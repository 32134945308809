import React from 'react';
import * as Styles from './styles';
import Collection from './partials/Collection';
import MultiColumnBlock from './partials/Multicolumn/MultiColumnBlock';
import BannerV2 from './partials/BannerV2';
import CopyV2 from './partials/CopyV2/CopyV2';
import Banner from './partials/Banner';
import Copy from './partials/Copy';
import HalfBannerV2 from './partials/HalfBannerV2';
import SideImageV2 from './partials/SideImageV2';
import { FAQV2Block } from './partials/FAQV2';
import Legal from './partials/Legal';
import { Shopstory } from '@shopstory/react';
import { RenderableContent, isRenderableContent } from '@shopstory/core';
import { RootLevelBlock, LandingPageBlockFlat } from '../../services/contentful/helpers';
import { ContentType } from 'types/contentful';

type Props = {
  blocks: RootLevelBlock[];
  animateTypographies?: boolean;
};

/**
 * BlockRenderers keys correspond to Contentful Entry types
 * Values correspond to components that get passed the entries
 */
const BlockRenderers = {
  LandingPageBannerBlock: Banner,
  LandingPageCollectionBlock: Collection,
  LandingPageCopyBlock: Copy,
  // V2 Contentful Components
  LegalPageBlock: Legal,
  landingPageFaqBlockV2: FAQV2Block,
  LandingPageBannerBlockV2: BannerV2,
  LandingPageCopyBlockV2: CopyV2,
  LandingPageHalfBannerBlockV2: HalfBannerV2,
  LandingPageSideImageBlockV2: SideImageV2,
  LandingPageMultiColumnBlockV2: MultiColumnBlock
};

type LandingPageBlockProps = {
  animateTypographies?: boolean;
  block: LandingPageBlockFlat;
};

const LandingPageBlock: React.FC<LandingPageBlockProps> = ({ block, animateTypographies }) => {
  return (
    <Styles.BlockContainer animateTypographies={animateTypographies} {...block}>
      {/* page is landingpageblocks (potentially with shopstory blocks inside) */}
      {(block.blocks || []).map((item, key) => {
        const blockType = item.contentType as ContentType;
        const BlockComponent = BlockRenderers[blockType];
        return <BlockComponent {...(item as any)} key={key} />;
      })}
    </Styles.BlockContainer>
  );
};

type RootLevelSectionProps = {
  data: LandingPageBlockFlat | RenderableContent;
  animateTypographies?: boolean;
};

const RootLevelSection: React.FC<RootLevelSectionProps> = ({ data, animateTypographies }) => {
  if (isRenderableContent(data)) {
    return <Shopstory content={data} />;
  } else {
    return <LandingPageBlock key={data.id} block={data} animateTypographies={animateTypographies} />;
  }
};

export default function Contentful(props: Props) {
  const { blocks = [], animateTypographies = false } = props;

  const hasFaqBlock = blocks.some(
    lpBlock =>
      !isRenderableContent(lpBlock.data) &&
      lpBlock.data.blocks?.some(block => block.contentType === 'landingPageFaqBlockV2')
  );

  return (
    <main itemScope={hasFaqBlock} itemType={hasFaqBlock ? 'https://schema.org/FAQPage' : undefined}>
      {blocks.map(block => {
        return <RootLevelSection data={block.data} animateTypographies={animateTypographies} key={block.id} />;
      })}
    </main>
  );
}
